<template>
  <!-- 课程资料 -->
  <div class="main-contain">
    <div class="contain course_contain">
      <el-table ref="singleTable" row-key="id" :data="tableData" highlight-current-row class="Table"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column label="文件名" min-width="600px">
          <template slot-scope="scope">
            <div class="item-left">
              <el-image v-if="!scope.row.file_type" class="elimage" :src="fileicon1" />
              <el-image v-else class="elimage" :src="file_icon(scope.row.ext)" />
              <div class="title">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小" min-width="500px">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">{{ scope.row.size }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100px">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" v-if="scope.row.file_type" @click="lookbt(scope.row)">查看</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pag-table class="paginationBox" :total="total" :page="page" :limit="per_page" @changeSize="changeSize"
        @changePage="changePage" style="margin-top:10px;text-align: center;" />
    </div>
    <Customdialog ref="customdialog" class="customdialog" width="70%" type="form" title="预览" :showclose="true">
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video class="video_view" ref="vueRef" width="100%" height="500" controls
            controlslist="nodownload noplaybackrate" disablePictureInPicture :src="videoviewurl" />
        </template>
        <template v-if="type == '图片'">
          <el-image class="image_view" :src="imageviewurl" fit="contain" />
        </template>
        <template v-if="type == '其他'">
          <!-- <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              该文件暂时不支持在线预览，您可以下载后查看！
            </div>
          </div> -->
          <iframe class="iframe_cont" :src="file_url" frameborder="0"></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <!-- <div class="pdf_con">
            <md-contract :url="fileurl" :visible="contractVisable" :showBtns="true" @handleModal="close">
            </md-contract>
          </div> -->
          <iframe class="iframe_cont" :src="fileurl" frameborder="0"></iframe>
        </template>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download" v-if="!is_download">下载</el-button>
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import pagTable from "@/components/Pagination/pagTable";
import Customdialog from "@/components/customdialog.vue";
import { getattachmentlist } from "@/api/attachment";
export default {
  name: "virtualexperiment",
  components: {
    pagTable,
    Customdialog,
  },
  data() {
    return {
      contractVisable: false,
      fileurl: "",
      file_url: "",
      filename: "",
      fileid: "",
      type: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      course_sn: "",
      tableData: [],
      per_page: 10, // 每页条数
      page: 1,
      total: 0,


      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-视频.png"),
      fileicon5: require("@/assets/fileicon/icon-图片.png"),
      fileicon6: require("@/assets/fileicon/icon-小结.png"),
      fileicon7: require("@/assets/fileicon/icon-音频.png"),
      fileicon8: require("@/assets/fileicon/icon-章节.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),


      is_download: 0,
      itemObj: {}
    };
  },
  methods: {
    initdata(obj) {
      // console.log("obj",obj);
      this.course_sn = obj.course_sn;
      this.getattachmentlist();
    },
    file_icon(val) {
      switch (val) {
        // case "file":
        //   return this.fileicon1;

        case "pdf":
          return this.fileicon2;

        case ("word", "docx"):
          return this.fileicon3;

        case "mp4":
          return this.fileicon4;

        case ("png", "jpg"):
          return this.fileicon5;

        case "radio":
          return this.fileicon7;

        case "rar":
          return this.fileicon9;

        case "txt":
          return this.fileicon11;

        case "xlsx":
          return this.fileicon12;

        case "zip":
          return this.fileicon13;

        default:
          return this.fileicon10;
      }
    },
    lookbt(item) {
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频";
        this.videoviewurl = item.url;
      } else if (item.ext == "png" || item.ext == "jpg") {
        this.type = "图片";
        this.imageviewurl = item.url;
      } else if (item.ext == "pdf") {
        this.type = "PDF";
      } else {
        this.type = "其他";
        this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(item.url);
      }

      this.itemObj = item
      this.is_download = item.is_download
      this.fileurl = item.url;
      this.filename = item.name;
      this.fileid = item.id;
      this.$refs.customdialog.dialogopenbt();
      this.contractVisable = true;
    },
    close() {
      this.contractVisable = false;
      this.contractVisable = false;
    },
    // 下载
    download() {
      let row = this.itemObj
      //1.先创建一个a标签
      let a = document.createElement("a");
      a.target = '_bank'
      a.download = row.name;
      //2.给a标签的href赋上下载地址
      a.href = row.url;
      //3.让a标签不显示在视图中
      a.style.display = "none";
      //4.将a标签append到文档中
      document.body.appendChild(a);
      //5.a标签自点击
      a.click();
      //6.点击下载后，销毁这个节点
      document.body.removeChild(a);
    },
    close_down() {
      this.$refs.customdialog.dialogclosebt();
      let _vueRef = this.$refs.vueRef
      if (this.videoviewurl !== '') {
        _vueRef.pause(); //暂停
      }
      setTimeout(() => {
        this.itemObj = {}
        this.is_download = 0
      }, 500)
    },

    getattachmentlist() {
      let params = {
        course_sn: this.course_sn,
        type: 0,
        per_page: this.per_page, // 每页条数
        page: this.page,
      }
      getattachmentlist(params).then((response) => {
        this.tableData = response.data.data;
        this.total = response.data.total
      }).catch((error) => {
        console.log(error);
      });
    },
    // 分页
    changeSize(data) {
      this.per_page = parseInt(data);
      this.getattachmentlist();
    },
    changePage(data) {
      this.page = parseInt(data);
      this.getattachmentlist();
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 10px;
  // margin-left: 10px;
  // margin-right: 10px;
  // height: 200px;
  width: 100%;
  // position: relative;
  // overflow: hidden;

  .contain {
    // position: absolute;
    width: 100%;
    height: 100%;

    .Table {
      width: 100%;

      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;

        .elimage {
          width: 25px;
          height: 25px;
        }

        .title {
          margin-left: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }
      }

      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .item-right {
        .bt {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }

  .customdialog {
    .dialogbody {
      padding: 10px;

      .image_view {
        // height: 600px;
        width: 100%;
      }

      .pdf_con {
        // position: relative;
      }

      .tip_con {
        height: 60px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #1890ff;
          color: white;
        }

        .tip_tex {
          font-size: 20px;
          font-weight: bold;
          color: #999999;
        }
      }
    }

    .dialogfooter {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep .cell {
    display: flex !important;
  }
}
</style>
