<template>
  <!-- 作业考试 -->
  <div class="main-contain">
    <div class="contain">
      <div class="rediobt">
        <el-radio-group v-model="radio" @change="change">
          <el-radio :label="0">未提交</el-radio>
          <el-radio :label="1">已提交</el-radio>
        </el-radio-group>
      </div>
      <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" :show-header="false"
        class="Table">
        <el-table-column width="400px">
          <template slot-scope="scope">
            <div class="item-left">
              <el-image class="elimage" :src="icon1" />
              <div class="text">
                <div class="title">{{ scope.row.name }}</div>
                <div class="time-contain">
                  <div class="time">{{ scope.row.end_time | dataformat }}</div>

                  <div class="time">截止</div>
                  <div class="bt">{{ scope.row.type | formatstatus }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">题目数量</div>
              <div class="num">{{ scope.row.total_stem_count }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">总分数</div>
              <div class="num">{{ scope.row.total_score }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">关联章节</div>
              <div class="look" @click="lookbt(scope.row.test_id)">查看</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-right">
              <template v-if="btstate == 0 && scope.row.access_status == 1">
                <div class="bt" @click="toanswer(scope.row.test_period_id, scope.row.status)">
                  答题
                </div>
              </template>
              <template v-if="btstate == 1">
                <div v-if="scope.row.review_status == 0">未批阅</div>
                <div v-if="scope.row.review_status == 2">
                  {{ scope.row.user_score }}
                </div>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <pag-table class="paginationBox" :total="total" :page="page" :limit="per_page" @changeSize="changeSize"
        @changePage="changePage" style="margin-top:10px;text-align: center;" />
    </div>

    <Customdialog ref="customdialog" type="max" class="customdialog" title="关联章节" width="800px" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Table ref="table"></Table>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>
<script>
const statusmap = ["其他", "作业", "考试"];
import dayjs from "dayjs";
import Table from "./table";
import Customdialog from "@/components/customdialog.vue";
import pagTable from "@/components/Pagination/pagTable";
import { gettestperiodList } from "@/api/testperiod";
export default {
  name: "virtualexperiment",
  components: {
    Customdialog,
    Table,
    pagTable
  },

  data() {
    return {
      radio: 0,
      obj: "",
      btstate: 0, //0 未提交 1已提交
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/组 10.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      per_page: 10, // 每页条数
      page: 1,
      total: 0
    };
  },
  filters: {
    formatstatus(val) {
      return statusmap[val];
    },

    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    initdata(obj) {
      this.obj = obj.course_sn;
      this.gettestperiodList({
        per_page: this.per_page, // 每页条数
        page: this.page,
        course_sn: obj.course_sn,
        id_type: 1,
        test_period_user_status: 0, //用户试卷状态，0：未提交，1：已提交
      });
    },
    change(val) {
      this.handinbt(val);
    },
    lookbt(id) {
      this.$refs.customdialog.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.table.initdata(this.obj, id);
      });
    },
    handinbt(state) {
      this.btstate = state;
      if (state == 0) {
        this.gettestperiodList({
          per_page: this.per_page, // 每页条数
          page: this.page,
          course_sn: this.obj,
          id_type: 1,
          test_period_user_status: 0,
        });
      }

      if (state == 1) {
        this.gettestperiodList({
          per_page: this.per_page, // 每页条数
          page: this.page,
          course_sn: this.obj,
          id_type: 1,
          test_period_user_status: 1, //用户试卷状态，0：未提交，1：已提交
        });
      }
    },
    toanswer(id, status) {
      if (status == 2) {
        // console.log("考试作业的状态", status, "考试已结束");
        this.$message({
          message: "已过截止时间,无法答题",
          type: "warning",
        });
      } else {
        let obj1 = this.obj;
        this.$router.push({
          path: "/home/wisdompracticeteaching/answer",
          query: {
            test_period_id: id,
            courseinfo: obj1,
          },
        });
      }
    },
    gettestperiodList(params) {
      this.tableData = [];
      gettestperiodList(params)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 分页
    changeSize(data) {
      this.per_page = parseInt(data);
      this.change(0);
    },
    changePage(data) {
      this.page = parseInt(data);
      this.change(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 20px;
  // margin-left: 10px;
  // margin-right: 10px;
  min-height: 400px;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .contain {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .rediobt {
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;

      .btitem {
        margin-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .circle1 {
          width: 33px;
          height: 33px;
          background: #ffffff;
          box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .circle0 {
            width: 17px;
            height: 17px;
            background: #8a8a8a;
            border-radius: 50%;
          }

          .bt-color {
            background: #2fbd56;
          }
        }

        .bt-title {
          margin-left: 6px;
          font-size: 18px;

          font-weight: bold;
          color: #8a8a8a;
          user-select: none;
          white-space: nowrap;
        }

        .bt-color {
          color: #2fbd56;
        }
      }
    }

    .Table {
      width: 100%;

      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .elimage {
          margin-bottom: 6px;
        }

        .text {
          margin-left: 10px;

          .title {
            font-size: 15px;
            font-weight: 400;
            color: #222222;
            white-space: nowrap;
          }

          .time-contain {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .time {
              margin-right: 4px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }

            .bt {
              margin-left: 4px;
              width: 53px;
              height: 27px;
              line-height: 27px;
              text-align: center;
              background: #d9fae2;
              border: 1px solid #2fbd56;
              border-radius: 4px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #2fbd56;
              user-select: none;
              cursor: pointer;
            }
          }
        }
      }

      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
          text-align: center;
        }

        .num {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }

        .look {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
          text-align: center;
        }
      }

      .item-right {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .bt {
          width: 72px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background: #3d84ff;
          border-radius: 4px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
