<template>
  <div class="main-contain">
    <div class="check-group">
      <el-radio-group v-model="radio" @change="changebt">
        <el-radio :label="0">全部</el-radio>
        <el-radio :label="1">仅老师参与</el-radio>
        <el-radio :label="2">回复最多</el-radio>
      </el-radio-group>
    </div>
    <div class="item" v-for="(item, index) in qadata" :key="index">
      <div class="title" @click="openreplypanel(item)">
        {{ item.title }}
        <span v-if="item.is_top === 1" class="item-two-bt-active"> 置顶 </span>
        <span v-if="item.is_teacher_join === 1" class="item-two-bt">
          老师参与
        </span>
      </div>
      <div class="text">
        {{ item.content }}
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar == null ? image09 : item.avatar" />
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <el-image class="elimage1" :src="icon1" />
            <div class="num">{{ item.view_count }}</div>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="setlikebt(item.id)">
            <template v-if="item.like_status === 0">
              <el-image class="elimage2" :src="icon2" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-if="item.like_status === 1">
              <el-image class="elimage2" :src="icon4" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="openreplypanel(item)">
            <el-image class="elimage3" :src="icon3" />
            <div class="num">{{ item.reply_count }}</div>
          </div>
        </div>
      </div>
      <div :class="index % 2 == 0 ? 'line3' : ''"></div>
    </div>
    <!-- 分页 -->
    <pag-table class="paginationBox" :total="total" :page="page" :limit="per_page" :pageSizes="[5, 10, 20]"
      @changeSize="changeSize" @changePage="changePage" style="margin-top:10px;text-align: center;" />

    <Customdialog ref="customdialog" :type="'max'" width="50%" title="互动问答回复" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Interactiveanswerreply ref="replypanel"></Interactiveanswerreply>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import { getQaList, setQaLike } from "@/api/qa";
import dayjs from "dayjs";
import Interactiveanswerreply from "./interactiveanswerreply";
import pagTable from "@/components/Pagination/pagTable";
import Customdialog from "@/components/customdialog.vue";
const cityOptions = ["仅老师参与", "回复最多"];
export default {
  name: "virtualexperiment",
  components: {
    pagTable,
    Customdialog,
    Interactiveanswerreply,
  },
  data() {
    return {
      id: "",
      radio: 0,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      image09: require("@/assets/logo2018.png"),
      icon4: require("@/assets/coursedetails/dzxz.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      qadata: [],
      per_page: 5, // 每页条数
      page: 1,
      total: 0,
      queryParams: {},
    };
  },
  created() {
    this.queryParams = {
      keyword: "",
      belong_type: 0,
      type: 0,
      related_type: 1,
      related_id: "",
    }
  },
  methods: {
    initdata(obj) {
      this.id = obj.course_sn;
      this.getqalist();
    },
    openreplypanel(obj) {
      this.$refs.customdialog.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.replypanel.initdata(obj);
      });
    },
    getqalist() {
      this.qadata = [];
      this.queryParams.course_sn = this.id;
      this.queryParams.per_page = this.per_page;
      this.queryParams.page = this.page;
      let params = this.queryParams;
      getQaList(params).then((response) => {
        if (response && response.code == 0) {
          this.total = response.data.total
          let newData = response.data.data
          if (newData && newData.length > 0) {
            newData.forEach((item) => {
              item.created_at = dayjs.unix(item.created_at).format("YYYY-MM-DD h:mm:ss");
            });
            this.qadata = newData;
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    // 分页
    changeSize(data) {
      this.per_page = parseInt(data);
      this.changebt(0);
    },
    changePage(data) {
      this.page = parseInt(data);
      this.changebt(0);
    },
    toreply(item) {
      let obj = JSON.stringify(item);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/interactiveanswerreply",
        query: {
          id: this.vr_id,
          item: encodeURIComponent(obj),
        },
      });
    },
    changebt(value) {
      this.queryParams.type = parseInt(value);
      this.getqalist();
    },
    setlikebt(id) {
      let params = { id: id };
      setQaLike(params).then((response) => {
        this.queryParams.type = 0;
        this.getqalist();
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  width: 100%;
  margin-top: 20px;
  // margin-left: 40px;
  // margin-right: 10px;
  overflow-y: auto;

  // min-height: 460px;
  .check-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .check-all {
      margin-right: 20px;
    }
  }

  .item {
    padding-top: 20px;

    margin-top: 10px;

    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;
      display: flex;
      cursor: pointer;

      .item-two-bt-active {
        border: 1px solid #ff4800;
        color: #ff4800;
        font-size: 12px;
        width: 40px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin-left: 10px;
        margin-top: 3px;
        margin-right: 10px;
        border-radius: 4px;
      }

      .item-two-bt {
        width: 60px;
        height: 21px;
        line-height: 21px;
        background: #ffffff;
        border: 1px solid #409eff;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #409eff;
        text-align: center;
        margin-top: 3px;
        margin-left: 10px;
      }
    }

    .item-two {
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .text {
      margin-top: 10px;
      font-size: 15px;

      font-weight: 400;
      color: #222222;
    }

    .bottom-contain {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .elimage1 {
            width: 22px;
            height: 16px;
          }

          .elimage2 {
            width: 19px;
            height: 21px;
            margin-bottom: 4px;
          }

          .elimage3 {
            width: 19px;
            height: 16px;
          }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line {
          margin-left: 20px;
          margin-right: 20px;
          height: 25px;
          border-right: 2px solid #9b9b9b;
        }
      }
    }

    .line3 {
      border-bottom: 1px solid #ebeef5;
      margin-top: 40px;
      margin-right: 60px;
    }
  }
}
</style>
