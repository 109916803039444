<template>
  <!-- 课程大纲 -->
  <div class="main-contain">
    <template v-if="coursechaptersinfo && coursechaptersinfo.length > 0">
      <div class="item" v-for="(item0, index) in coursechaptersinfo" :key="index + 'jhkjh_' + item0.id">
        <div class="title">
          <div class="title">{{ item0.number }}</div>
          <div class="title">{{ item0.name }}</div>
        </div>
        <template v-if="item0.outline_file_list && item0.outline_file_list.length > 0">
          <div class="second-item second-item_p" v-for="(item4, index) in item0.outline_file_list"
            :key="item4.id + index + '_jhjh'">
            <div class="itemleft" @click="lookbt(item4)">
              <el-image class="elimage" :src="file_icon(item4.ext)" />
              <div class="title">{{ item4.name }}</div>
            </div>
            <div class="itemright">
              <!-- <el-image class="elimage" :src="viewicon" /> -->
            </div>
          </div>
        </template>
        <template v-if="item0.children && item0.children.length > 0">
          <div class="item-item" v-for="(item1, index) in item0.children" :key="index">
            <div class="item-item-title">
              <div class="itemleft">
                <div class="icon"></div>
                <div class="num">{{ item1.number }}</div>
                <div class="text">{{ item1.name }}</div>
              </div>
              <div class="itemright">
                <el-progress :stroke-width="8" :percentage="
                  item1.progress ? parseInt(item1.progress * 100) : 0
                "></el-progress>
              </div>
            </div>
            <!-- <div  class="line"></div> -->
            <template v-if="
              item1.outline_file_list && item1.outline_file_list.length > 0
            ">
              <div class="line"></div>
              <div class="second-item" v-for="(item2, index) in item1.outline_file_list"
                :key="index + '_dsweds' + item2.id">
                <div class="itemleft" @click="lookbt(item2)">
                  <el-image class="elimage" :src="file_icon(item2.ext)" />
                  <div class="title">{{ item2.name }}</div>
                </div>
                <div class="itemright">
                  <!-- <el-image class="elimage" :src="viewicon" /> -->
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <Customdialog ref="customdialog" class="customdialog" width="70%" type="form" title="预览" :showclose="false">
      <div class="dialogbody" slot="dialogbody">
        <template v-if="type == '视频'">
          <video class="video_view" id="myvideo" width="100%" height="500" controls
            controlslist="nodownload noplaybackrate" disablePictureInPicture :src="videoviewurl" @play="getVidDur()"
            ref="vueRef" />
          <div class="tip_con" style=" background: none; border: none;">
            <div class="icon" style=" background-color: red;">!</div>
            <div class="tip_tex" style="color: red;">
              初次观看视频无法快进，学习完成后才能快进！
            </div>
          </div>
        </template>
        <template v-if="type == '图片'">
          <el-image class="image_view" :src="imageviewurl" fit="contain" />
        </template>
        <template v-if="type == '其他'">
          <!-- <div class="tip_con">
            <div class="icon">!</div>
            <div class="tip_tex">
              该文件暂时不支持在线预览，您可以下载后查看！
            </div>
          </div> -->
          <iframe class="iframe_cont" :src="file_url" frameborder="0"></iframe>
        </template>
        <template v-if="type == 'PDF'">
          <!-- <div class="pdf_con">
            <md-contract :url="fileurl" :visible="contractVisable" :showBtns="true" @handleModal="close">
            </md-contract>
          </div> -->
          <iframe class="iframe_cont" :src="fileurl" frameborder="0"></iframe>
        </template>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button type="primary" @click="download()" v-if="!is_download">下载</el-button>
        <el-button type="info" @click="close_down">关闭</el-button>
      </div>
    </Customdialog>
  </div>
</template>
<script>
// import axios from "axios";
import Customdialog from "@/components/customdialog.vue";
import { getoutlinelist, currentProgress } from "@/api/outline";
import mdContract from "@/contract.md.vue";
var video = () => {
  let videoTime = document.getElementById("myvideo");
  console.log(parseInt(videoTime.duration)); //获取视频时长
  console.log(videoTime.currentTime); //获取视频当前播放时间
};
export default {
  name: "courseoutline",
  components: {
    Customdialog,
    mdContract,
  },
  data() {
    return {
      contractVisable: false,
      fileurl: "",
      file_url: "",
      filename: "",
      fileid: "",
      type: "",
      videoviewurl: "",
      player: '',
      imageviewurl: "",
      coursechaptersinfo: "",
      viewicon: require("@/assets/查看.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/文件类型-标准图-Word文档.png"),
      icon3: require("@/assets/coursedetails/文件类型-标准图-幻灯片.png"),
      icon4: require("@/assets/coursedetails/文件类型-标准图-声音文件.png"),
      icon5: require("@/assets/coursedetails/文件类型-标准图-图片文件.png"),

      fileicon1: require("@/assets/fileicon/files.png"),
      fileicon2: require("@/assets/fileicon/icon-PDF.png"),
      fileicon3: require("@/assets/fileicon/icon-word.png"),
      fileicon4: require("@/assets/fileicon/icon-视频.png"),
      fileicon5: require("@/assets/fileicon/icon-图片.png"),
      fileicon6: require("@/assets/fileicon/icon-小结.png"),
      fileicon7: require("@/assets/fileicon/icon-音频.png"),
      fileicon8: require("@/assets/fileicon/icon-章节.png"),
      fileicon9: require("@/assets/fileicon/rar.png"),
      fileicon10: require("@/assets/fileicon/single.png"),
      fileicon11: require("@/assets/fileicon/txt.png"),
      fileicon12: require("@/assets/fileicon/xlsx.png"),
      fileicon13: require("@/assets/fileicon/zip.png"),
      outline_file_id: "",
      course_sn: "",
      outline_file_len: "",
      progress: "",
      is_download: 0,
      itemObj: {}
    };
  },
  filters: {},
  created() {
    this.course_sn = decodeURIComponent(this.$route.query.course_sn);
    this.getoutlinelist({
      course_sn: this.course_sn,
      is_show_progress: 1,
    });
  },
  methods: {
    //视频进度历史
    getVidDur() {
      // debugger
      // console.log(this.progress, '--------this.progress');
      let thisVideo = document.getElementById("myvideo");
      // console.log(parseInt(thisVideo.duration)); //获取视频时长
      if (this.progress === 1) {
        return false
      } else {
        if (this.progress >= 0) {
          let num = thisVideo.duration * this.progress
          thisVideo.currentTime = Math.floor(num * 100) / 100
        }
      }
    },

    initdata() {
      this.getoutlinelist({
        course_sn: this.course_sn,
        is_show_progress: 1,
      })
    },
    file_icon(val) {
      switch (val) {
        case "file":
          return this.fileicon1;

        case "pdf":
          return this.fileicon2;

        case ("word", "docx"):
          return this.fileicon3;

        case "mp4":
          return this.fileicon4;

        case ("png", "jpg"):
          return this.fileicon5;

        case "radio":
          return this.fileicon7;

        case "rar":
          return this.fileicon9;

        case "txt":
          return this.fileicon11;

        case "xlsx":
          return this.fileicon12;

        case "zip":
          return this.fileicon13;

        default:
          return this.fileicon10;
      }
    },
    lookbt(item) {
      if (item.progress !== null) {
        this.progress = Number(item.progress)
      } else {
        this.progress = 0
      }
      this.outline_file_id = item.id;
      this.outline_file_len = item.length;
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频";
        this.videoviewurl = item.url;
      } else if (item.ext == "png" || item.ext == "jpg") {
        this.type = "图片";
        this.imageviewurl = item.url;
      } else if (item.ext == "pdf") {
        this.type = "PDF";
      } else {
        this.type = "其他";
        this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(item.url);
      }

      this.itemObj = item
      this.is_download = item.is_download
      this.fileurl = item.url;
      this.filename = item.name;
      this.fileid = item.id;
      this.$refs.customdialog.dialogopenbt();
      this.contractVisable = true;
    },
    close() {
      this.contractVisable = false;
      this.contractVisable = false;
    },
    // 下载
    download() {
      // fetch(this.fileurl).then(res => res.blob()).then(blob => {
      //   const a = document.createElement("a");
      //   const objectUrl = window.URL.createObjectURL(blob);
      //   a.download = this.filename;
      //   a.href = objectUrl;
      //   a.click();
      //   window.URL.revokeObjectURL(objectUrl);
      //   a.remove();
      // })

      // if (this.outline_file_len) {
      //   let params = {
      //     outline_file_id: this.outline_file_id,
      //     time: this.outline_file_len,
      //   }
      //   this.setCurrentProgress(params)
      // }

      let row = this.itemObj
      //1.先创建一个a标签
      let a = document.createElement("a");
      a.target = '_bank'
      a.download = row.name;
      //2.给a标签的href赋上下载地址
      a.href = row.url;
      //3.让a标签不显示在视图中
      a.style.display = "none";
      //4.将a标签append到文档中
      document.body.appendChild(a);
      //5.a标签自点击
      a.click();
      //6.点击下载后，销毁这个节点
      document.body.removeChild(a);
    },
    close_down() {
      this.$refs.customdialog.dialogclosebt();
      let videoTime = document.getElementById("myvideo");
      if (this.videoviewurl !== '') {
        if (videoTime.currentTime) {
          this.$refs.vueRef.pause(); //暂停
          let params = {
            outline_file_id: this.outline_file_id,
            time: videoTime.currentTime,
          }
          this.setCurrentProgress(params)
        }
      } else {
        if (this.outline_file_len) {
          let params = {
            outline_file_id: this.outline_file_id
          }
          if (this.progress === "" || this.progress < 1) { //未打开过小节(未读)或未完成100%
            params.time = this.outline_file_len
            this.setCurrentProgress(params)
          }
        }
      }
      this.videoviewurl = ""
      setTimeout(() => {
        this.itemObj = {}
        this.is_download = 0
      }, 500)
    },
    getoutlinelist(params) {
      getoutlinelist(params)
        .then((response) => {
          this.coursechaptersinfo = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setCurrentProgress(data) {
      currentProgress(data).then(res => {
        if (res.code === 0) {
          // this.$message.success('进度上传成功!')
          this.initdata()
        }
      }).catch(error => {
        console.log(error);
      })
    }
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  border: 1px solid transparent;
  margin-top: 10px;

  // height: 100%;
  .item {
    // border: 1px solid red;
    margin-top: 20px;
    margin-left: 30px;

    .title {
      //  border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .title {
        margin-right: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
    }

    .second-item_p {
      padding-left: 20px;
      padding-right: 20px;
    }

    .second-item {
      // border: 1px solid red;
      box-sizing: border-box;
      // padding-left: 40px;
      // padding-right: 40px;
      margin-top: 10px;
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .itemleft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .elimage {
          width: 20px;
          height: 20px;
        }

        .title {
          margin-left: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #666666;
        }
      }

      .itemright {
        font-size: 15px;
        font-weight: 400;
        color: #3d84ff;
        cursor: pointer;
        user-select: none;
      }
    }

    .item-item {
      margin-top: 10px;
      margin-left: 70px;
      // width: 1126px;
      // height: 118px;
      background: #f9f9f9;
      box-sizing: border-box;
      padding: 20px;

      .item-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemleft {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .icon {
            width: 6px;
            height: 6px;
            background: #3d84ff;
            border-radius: 50%;
          }

          .num {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 18px;

            font-weight: 400;
            color: #666666;
          }

          .text {
            font-size: 18px;

            font-weight: 400;
            color: #666666;
          }
        }

        .itemright {
          // border: 1px solid red;
          width: 150px;
          text-align: right;

          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          .text {
            margin-left: 4px;
            font-size: 15px;

            font-weight: 400;
            color: #3d84ff;
          }
        }
      }

      .line {
        margin-left: 20px;
        margin-top: 10px;
        border-bottom: 1px solid #EBEEF5;
      }

      .second-item {
        margin-top: 10px;
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .itemleft {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .elimage {
            width: 20px;
            height: 20px;
          }

          .title {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 400;
            color: #666666;
          }
        }

        .itemright {
          font-size: 15px;
          font-weight: 400;
          color: #3d84ff;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .customdialog {
    .dialogbody {
      // border: 1px solid red;
      padding: 10px;

      .video_view {}

      .image_view {
        height: 600px;
        width: 100%;
      }

      .pdf_con {
        // border: 1px solid red;
        position: relative;
      }

      .tip_con {
        height: 60px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          background: #1890ff;
          color: white;
        }

        .tip_tex {
          font-size: 20px;
          font-weight: bold;
          color: #999999;
        }
      }
    }

    .dialogfooter {
      // border: 1px solid red;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
