<template>
  <!-- 课程学习 -->
  <div class="main-contain_con">
    <div class="contain_up">
      <div class="title_con">
        <div class="title_text_border">
          <div class="left_circle"></div>
          <div class="right_circle"></div>
          <div class="title_text">{{ coursedesc ? coursedesc.name : "" }}</div>
          <div class="time_text">
            开课时间: {{ coursedesc.start_time | dataformat }} ~
            {{ coursedesc.end_time | dataformat }}
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="left_cont">
        <div class="introduction">
          <el-row class="elrow">
            <el-col :span="10" class="elcol-left">
              <el-image class="elimage" :src="coursedesc.cover" />
              <el-image class="statusimg" :src="statusImg" />
            </el-col>
            <el-col :span="14" class="elcol-right">
              <div class="intro-title">
                <div class="item">
                  <div class="item-name">所属学校：</div>
                  <div class="item-text">
                    {{coursedesc.school_name }}
                  </div>
                </div>
                <div class="item">
                  <div class="item-name">所属学科：</div>
                  <div class="item-text">
                    {{
                    coursedesc && coursedesc.subject_config_info
                    ? coursedesc.subject_config_info.name
                    : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="course-item">
                <div class="left-title">
                  <div class="coursename">课程介绍：</div>
                </div>
                <div class="right-text">
                  <div class="text">
                    {{ coursedesc ? coursedesc.brief : "" }}
                  </div>
                </div>
              </div>
              <div class="course-item">
                <div class="left-title">
                  <div class="coursename">教师：</div>
                </div>
                <div class="right-text">
                  {{ teachteam }}
                </div>
              </div>
              <div class="intro-title" style="margin-top: 20px;">
                <div class="item">
                  <div class="item-name">学分：</div>
                  <div class="item-text">
                    {{
                      coursedesc ? coursedesc.credit : ""
                    }}
                  </div>
                </div>
                <div class="item">
                  <div class="item-name">学时：</div>
                  <div class="item-text">
                    {{
                      coursedesc ? coursedesc.class_hours : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="bottom-title">
                <div class="item">
                  <div class="num">
                    {{ coursedesc ? coursedesc.join_count : "" }}
                  </div>
                  <div class="score">加课人数</div>
                </div>
                <div class="item">
                  <div class="num">
                    {{ coursedesc.collect_count}}
                  </div>
                  <div class="score">收藏人数</div>
                </div>
                <div class="item" @click="collectbt">
                  <template v-if="coursedesc.is_collect === 0">
                    <el-image class="elimage" :src="collecticon1" />
                    <div class="score">收藏</div>
                  </template>
                  <template v-if="coursedesc.is_collect === 1">
                    <el-image class="elimage" :src="collecticon2" />
                    <div class="score">已收藏</div>
                  </template>
                </div>
                <div class="item">
                  <div class="school_select">学校选课</div>
                </div>
                
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="bottom">
          <div class="bottom_con">
            <div class="tabbt-contain">
              <div :class="activebt === index ? 'bt btactive' : 'bt'" v-for="(item, index) in btlist" :key="index"
                @click.stop="btclick(index, item)">
                <!-- <el-image class="bt-icon" :src="item.icon2" /> -->
                <div :class="activebt == index ? 'bt-name bt-name-active' : 'bt-name'">{{ item.name
                }}</div>
              </div>
            </div>
            <component :is="componentId" ref="mycomponent"></component>
          </div>
        </div>
      </div>
      <div class="right_cont" v-if="resCourseList.length > 0">
        <div class="title">相关课程</div>
        <div class="rec_course">
          <div v-for="(item, inx) in resCourseList" :key="inx" class="course_block" @click="to_detail(item)">
            <div class="course_block_img">
              <el-image :src="item.cover" />
            </div>
            <el-tooltip :content="item.name" placement="top" effect="light" v-if="item.flag">
              <div class="course_block_text">{{ item.name }}</div>
            </el-tooltip>
            <div class="course_block_text" v-else>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getcourseinfo, getRecCourseList } from "@/api/course";
import dayjs from "dayjs";
import { getcourseteacherlist } from "@/api/courseteacher";
import { setuserfavorite } from "@/api/userfavorite";
import Courseoutline from "./components/courseoutline.vue";
import Virtualexperiment from "./components/virtualexperiment.vue";
import Homeworktest from "./components/homeworktest.vue";
import Courseinformation from "./components/courseinformation.vue";
import Discussion from "./components/discussion.vue";
import { getToken } from "@/utils/auth";
export default {
  name: "courselearning",
  components: {
    Courseoutline,
    Virtualexperiment,
    Homeworktest,
    Courseinformation,
    Discussion,
  },
  inject: ['reload'],
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      token: getToken() ? true : false,
      courseinfo: "",
      teachteam: "",
      coursedesc: "",
      componentId: "Courseoutline",
      statusImg: "",
      statusImg1: require("@/assets/coursedetails/st_not_start.png"),
      statusImg2: require("@/assets/coursedetails/st_ing.png"),
      statusImg3: require("@/assets/coursedetails/st_over.png"),
      testimage: require("@/assets/test.png"),
      collecticon1: require("@/assets/newchange/collect_11.png"),
      collecticon2: require("@/assets/newchange/collect_22.png"),
      btlist: [
        {
          icon: require("@/assets/coursedetails/课程管理 (1).png"),
          icon2: require("@/assets/coursedetails/new/课程管理.png"),
          name: "课程大纲",
          component: "Courseoutline",
        },
        {
          icon: require("@/assets/coursedetails/实验 烧杯 2.png"),
          icon2: require("@/assets/coursedetails/new/实验 烧杯 2.png"),
          name: "虚拟仿真实验",
          component: "Virtualexperiment",
        },
        {
          icon: require("@/assets/coursedetails/考试 (1).png"),
          icon2: require("@/assets/coursedetails/new/考试.png"),
          name: "作业考试",
          component: "Homeworktest",
        },
        {
          icon: require("@/assets/coursedetails/图层 61.png"),
          icon2: require("@/assets/coursedetails/new/图层 61.png"),
          name: "课程资料",
          component: "Courseinformation",
        },
        {
          icon: require("@/assets/coursedetails/问答2.png"),
          icon2: require("@/assets/coursedetails/new/互动管理.png"),
          name: "问答讨论",
          component: "Discussion",
        },
      ],
      activebt: 0,
      resCourseList: [],
    };
  },
  created() {
    this.activebt = 0;
    let course_sn = decodeURIComponent(this.$route.query.course_sn);
    this.courseinfo = course_sn;
    if (getToken()) {
      this.getteacherlist({
        course_sn: this.courseinfo,
      });
      this.getcourseinfo({
        course_sn: this.courseinfo,
      });
      this.initRecCourseList(course_sn)
    } else {
      this.$router.push({
        path: "/home/wisdompracticeteaching/coursedetails?course_sn=" + course_sn,
      });
    }

  },
  watch: {
    resCourseList: {
      handler(newVal) {
        let resDis = document.querySelector('.left_cont')
        if (newVal.length > 0) {
          resDis.style['width'] = 'calc(100% - 390px)'
          let dis = document.querySelector('.right_cont')
          if (dis) {
            dis.style['width'] = '390px'
          }
        } else {
          if (resDis) {
            resDis.style['width'] = '100%'
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initRecCourseList(data) {
      this.resCourseList = []
      getRecCourseList({ course_sn: data, page: 1, per_page: 10000 }).then(res => {
        if (res.code === 0) {
          res.data.data.map(item => {
            if (item.name.length > 14) {
              item.flag = true
            }
          })
          this.resCourseList = res.data.data
        }
      })
    },
    btclick(name, component) {

      this.activebt = name;
      this.componentId = component.component;
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(this.coursedesc);
      });
    },
    //收藏
    collectbt() {
      if (this.token) {
        this.setuserfavorite({
          course_sn: this.itemobj,
          type: 1,
        });
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => { });
      }
    },
    setuserfavorite(params) {
      setuserfavorite(params)
        .then((res) => {
          console.log(res, "res");
          if (res.code == 0) {
            this.getcourseinfo({
              course_sn: this.itemobj,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getteacherlist(params) {
      this.teachteam = [];
      getcourseteacherlist(params)
        .then((response) => {

          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              this.teachteam.push(item.name);
            });
            this.teachteam = this.teachteam.join("、");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getcourseinfo(params) {
      getcourseinfo(params)
        .then((response) => {
          if (response.code == 0) {
            this.coursedesc = response.data;
            if (response.data.course_status == 0) {
              this.statusImg = this.statusImg1;
            } else if (response.data.course_status == 1) {
              this.statusImg = this.statusImg2;
            } else {
              this.statusImg = this.statusImg3;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    to_detail(item) {
      // debugger
      if (item.is_join == 1) {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/courselearning?course_sn=" +
            item.course_sn,
        });
        this.reload()
      } else {
        this.$router.push({
          path:
            "/home/wisdompracticeteaching/coursedetails?course_sn=" +
            item.course_sn,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain_con {
  margin-bottom: 140px;

  .contain_up {
    background: url(~@/assets/newchange/bg_4444.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 298px;

    .title_con {
      background: rgba(0, 0, 0, 0.5);

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      .title_text_border {
        position: relative;
        min-width: 400px;
        height: 160px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .left_circle {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(-100%, -50%);
        }

        .right_circle {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 16px;
          height: 16px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          transform: translate(100%, 50%);
        }

        .bt {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 50%);
          width: 151px;
          height: 40px;
          background: #3d84ff;
          border-radius: 20px;
          border-color: #3d84ff;
          opacity: none;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
        }

        .title_text {
          margin-top: 20px;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
        }

        .time_text {
          margin-top: 20px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }

  .contain_down {
    width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: center;

    .left_cont {
      width: calc(100% - 390px);
    }

    .right_cont {
      width: 390px;
      padding-left: 150px;
      box-sizing: border-box;

      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        padding: 20px 0px 10px;
      }

      .rec_course {
        width: 240px;
        height: 1055px;
        overflow-y: scroll;


        .course_block {
          padding-top: 20px;
          cursor: pointer;

          .course_block_img {
            width: 100%;
            height: 146px;
            border-radius: 5px;
            overflow: hidden;

            ::v-deep .el-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .course_block_text {
            width: calc(100% - 20px);
            text-align: center;
            padding: 10px 10px 0px;
            font-size: 14px;
            line-height: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .course_block:first-child {
          padding-top: 10px;
        }

        .course_block:last-child {
          padding-bottom: 20px;
        }
      }

      ::v-deep .rec_course::-webkit-scrollbar {
        /*滚动条的宽度*/
        width: 0px;
      }
    }

    .introduction {
      margin-top: 25px;
      border-radius: 4px;

      .elrow {
        height: 100%;
        width: 100%;

        .elcol-left {
          position: relative;

          .elimage {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .statusimg {
            width: 80px;
            height: 80px;
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        .elcol-right {
          height: 100%;
          padding-left: 20px;

          .intro-title {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;

            .item {
              margin-top: 10px;
              // margin-right: 100px;
              width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                width: 20px;
                height: 20px;
              }

              .elimage2 {
                width: 23px;
                height: 23px;
              }

              .item-name {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                white-space: nowrap;
              }

              .item-text {
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                white-space: nowrap;
              }
            }

            .item:nth-child(1),
            .item:nth-child(2) {
              margin-top: 0px;
            }
          }

          .course-item {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: baseline;

            .left-title {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              white-space: nowrap;

              .coursename {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin-right: 10px;
              }
            }

            .right-text {
              position: relative;
              flex: 1;
              font-size: 14px;

              .text {
                font-size: 14px;
                line-height: 25px;
                font-weight: 400;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }

              .morebt {
                position: absolute;
                font-size: 16px;

                font-weight: 400;
                color: #3d84ff;
                bottom: 0px;
                right: 0px;
                user-select: none;
                cursor: pointer;
              }
            }

            .right-text2 {
              margin-left: -80px;
            }
          }

          .bottom-title {
            margin-left: 90px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // width: 40%;
            .item {
              margin-top: 10px;
              // margin-right: 100px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .school_select{
                width: 120px;
                height: 40px;
                line-height: 40px;
                border-radius: 25px;
                background: #0773FC;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
              }
              .score {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }

              .num {
                font-size: 30px;
                font-weight: 500;
                color: #3d84ff;
              }

              .numname {
                font-size: 20px;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
      }
    }
    ::v-deep .elimage {
      margin-bottom: 10px;
        top: 5px;
    }
    .bottom {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .bottom_con {
        width: 100%;
        min-height: 400px;

        .tabbt-contain {
          border-bottom: 1px solid #cccccc;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .bt {
            // margin-right: 90px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid transparent;
            user-select: none;
            cursor: pointer;

            .bt-icon {
              margin-right: 6px;
            }

            .bt-name {
              font-size: 18px;
              font-weight: 400;
              color: #222222;
            }

            .bt-name-active {
              color: #3d84ff;
            }
          }

          .btactive {
            border-bottom: 2px solid #3d84ff;
          }
        }
      }
    }
  }
}
</style>
